import React from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import Layout from "../../../../components/layout";
import OrderButton from "../../../../components/orderbutton";
import ProductTable from "../../../../components/productTable";
import SEO from "../../../../components/seo";
import { Col, Container, Row } from "react-bootstrap";
import Content from "../../../../content/produk/controller/videoprocessor/LISTEN VP1000/Product-Page.json";
import Datasheet from "../../../../content/produk/controller/videoprocessor/LISTEN VP1000/datasheet-listen-vp1000.pdf";
import ProductImage from "../../../../images/product/controller/videoprocessor/LISTEN VP1000/Product-Summary-Listen-VP1000.jpg";
const listenvp1000 = () => (
    <Layout>
        <SEO
            title={Content.seo.title}
            description={Content.seo.description}
            lang={Content.seo.lang}
        />
        <section id={"produk"} className={"section-bg"}>
            <Container>
                <div className={"section-title"}>
                    <h1>{Content.seo.title}</h1>
                </div>
                <Row>
                    <Col>
                        <Image src={ProductImage} fluid alt={"Gambar modul"} />
                    </Col>
                </Row>
                <Row className={"mt-5"}>
                    <Col>
                        <div className={"section-title"}>
                            <h2>Product Summary</h2>
                        </div>
                        <p>
                            Listen VP1000 LED Video Processor, has 6 channels of
                            video input, 3 channels of video output,1 channel of
                            audio output , It can be widely used in engineering,
                            rental performance, irregular/large LED display
                            screen/mixed LED display (different spacing), large
                            stage theater, display and exhibition, etc.
                        </p>
                        <ul>
                            <li>
                                Multiple video interface: Input has
                                HDMI,DVI,VGA,2xCVBS,SDI/USB(optional);
                            </li>

                            <li>Output has 2xDVI,VGA;</li>

                            <li>
                                Seamless witching/Fade-in-fade-out: Pictures
                                from 2 channels can be switched seamless, also
                                can be fade-in-fade-out
                            </li>

                            <li>
                                Multiple output resolution : Many resolution can
                                be selected (Max.2304x1152@60Hz) and
                                user-defined resolution
                            </li>

                            <li>
                                2.65 million pixels supported: Maximum height
                                can be 2000 pixels; maximum width can be 3960
                                pixels
                            </li>

                            <li>
                                Preset storage and recall: can switch to the
                                saved scene quickly
                            </li>

                            <li>
                                Audio and video synchronous: Synchronization of
                                audio and video switching
                            </li>

                            <li>
                                Chroma Key : Cut part image from one video ,then
                                put it on another video, for example image
                                overlapped
                            </li>

                            <li>
                                Support picture in picture (PIP) : The size and
                                location of the 2 pictures can be adjustable
                            </li>

                            <li>
                                Powerful Splicing function : Perfectly
                                splicing,support Uniform and not uniform
                                splicing, support 10X10 video processors to
                                splicing
                            </li>

                            <li>
                                User-defined input resolution : Can perfectly
                                realize the PC to LED screen point to point
                                display
                            </li>

                            <li>
                                Support project lock settings : To avoid the
                                wrong operation
                            </li>

                            <li>
                                Short key settings : Black output/Normal
                                output,Freeze, Part/Full Screen
                            </li>
                        </ul>
                        <p>
                            <OrderButton
                                messageText={Content.messageText}
                                datasheet={Datasheet}
                            />
                        </p>
                    </Col>
                    <Col>
                        <div className={"section-title"}>
                            <h2 className={"text-center"}>Specification</h2>
                        </div>
                        <ProductTable
                            tableName={"LED Unit Cabinet"}
                            tableData={Content.specification}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    </Layout>
);

listenvp1000.propTypes = {
    siteTitle: PropTypes.string,
};

listenvp1000.defaultProps = {
    siteTitle: ``,
};
export default listenvp1000;
